$(function() {
    window.setTimeout(function() {
        if ($(".bandeau_header .swiper-slide").length > 1) {
            promoSwiper = new Swiper(".bandeau_header .swiper-container", {
                centeredSlides: true,
                loop: true,
                slidesPerView: 1,
                speed: 1500,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                autoplayDisableOnInteraction: false
            });
        }
    }, 500);

    //SEARCH SCRIPTS
    $('#trigger_recherche').on("click touch", function() {

        if($('#site_head_wrap').hasClass('highlighted')) {

            closeRecherche();
        } else {

            $('.wrap_search_field').fadeIn().css("display", "flex");
            $('#site_head_wrap').addClass('highlighted');
            $('#shad_menu').addClass('actif');

            $('#shad_menu').on('click', function () {

                closeRecherche();
            });
        }
    });

    $('.close_search').on("click touch", function() {
        closeRecherche();
    });

    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if(value.length >= 3){
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });

    $('#search_home').keypress(function (e) {
        var key = e.which;

        if(key == 13 && $('#search_home').val().length >= 3){
            closeRecherche();
        }
    });

    //HOVER CART - WISHLIST

    // Gestion du rollover panier
    $(".my_basket").on("mouseenter", function() {
        topCartAppear();
    });

    $(".my_basket").on("mouseleave", function() {
        topCartDesappear();
    });

    // Gestion du rollover wishlist + account
    $(".head_top .my_account.is_logged, #wishlist_top.is_logged").on("mouseenter", function() {
        $('#shad_menu').addClass("actif");
        var count_wish = $('#wishlist_top #cart_list .product-link').length;
        if(count_wish >= 2){
            $("#wishlist_top .cart_product_listing").overlayScrollbars({});
        }
    });

    $(".head_top .my_account, #wishlist_top").on("mouseleave", function() {
        $('#shad_menu').removeClass("actif");
    });

    //HOVER MENU
    var menu_timeout = setTimeout(null);

    $(".main_menu_itm")
        .not(".menu_itm_hover")
        .on({
            mouseenter: function() {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            mouseleave: function() {
                menuItemClose();
            },
        });

    $(".menu_itm_hover").on("mouseenter", function(evt) {
        var mainmenu = $(".main_menu");
        var submenu = $(".submenu_wrapper", this);

        evt.preventDefault();

        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {
            $("#shad_menu").addClass("actif");

            mainmenu.addClass("actif");

            menu_timeout = setTimeout(function() {
                submenu.css("display", "block").fadeIn(200);
            }, 200);
        } else {
            submenu.css("display", "block");
        }
    });

    function menuItemClose(submenu) {
        clearTimeout(menu_timeout);

        if (submenu) {
            submenu.stop(true, true).fadeOut(200);
        }

        menu_timeout = setTimeout(function() {
            $("#shad_menu").removeClass("actif");
            $(".main_menu").removeClass("actif");
        });
    }

    $(document).on("scroll", function() {
        menuItemClose($(".submenu_wrapper:visible"));
    });

    $(".menu_itm_hover").on("mouseleave", function() {
        menuItemClose($(".submenu_wrapper", this));
    });

    /*FP sliders*/
    if($("#visual_scroller_wrapper").length) {
        var productThumbs = new Swiper('#visual_scroller', {
            slidesPerView: 4,
            spaceBetween: 8,
            direction: 'vertical',
            navigation: 
            {
                nextEl: '.thumbNext',
                prevEl: '.thumbPrev',
            },
        });
    }

    // PRODUCT SWIPER
    if($("#jqzoom").length) {
        var main_productVisu = new Swiper('#jqzoom', {
            direction: 'vertical',
            loadOnTransitionStart: true,
            thumbs: {
                swiper: productThumbs
            },
            slidesPerView: 1,
            lazy: true,
        });
    }

    // Chargement de la tarification
    if($("#tarification_load").length > 0){
        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + create_link('ajax_tarification_list'),
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (typeof(res) == 'object' && Object.keys(res).length > 0) {
                    var div = $('<div>', {'class': 'custom-select custom-select-footer'});
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", {value: key, text: val.pays_nom});
                        if (val.selected) {
                            option.prop('selected', true)
                        }
                        $(option).appendTo(select);
                    });
                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();
                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + create_link('ajax_tarification_change_country'),
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });
                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                    initCustomSelect('custom-select-footer');
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }


    /* HEADER STICKY SCROLL */

    var header = document.getElementById("site_head_wrap");

    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {

            if (scrollTop === 0) {
                header.classList.remove("sticky");
                $("body").removeClass("header_sticky");
            }
        } else {

            if (scrollTop !== 0) {
                header.classList.add("sticky");
                $("body").addClass("header_sticky");

            }
        }
    }

    $(window).on("scroll", onWinScroll);
    onWinScroll();

    //HP SLIDERS
    if ($(".cover-module .swiper-slide").length > 1) {

        var coverSwiper = new Swiper(".cover-module .swiper-container:not(.no-swiper)", {

            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            slidesPerView: 1,
            spaceBetween: 0,
            preloadImages: true,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            speed: 1500
        });

        var coverSwiperClass = ".cover-module .swiper-container";

        // store last played video to put in pause when slide
        var lastPlayedVideo = '';

        var youtubePlayers = {};
        // Cas YouTube
        if ($(coverSwiperClass + ' .swiper-slide iframe.youtube_video').length >= 1) {

            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);

            window.onYouTubeIframeAPIReady = function () {
                $(coverSwiperClass + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        //add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    coverSwiper.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });

            };
        }

        // Cas Video Vimeo
        var vimeoPlayers = {};
        if ($(coverSwiperClass + ' .swiper-slide .vimeo_video').length >= 1) {
            $(coverSwiperClass + ' .swiper-slide .vimeo_video').each(function () {

                var videoId = $(this).attr('id');
                var options = {background: true, loop: true, autoplay: true};
                var playerVimeo = new Vimeo.Player($(this), options);

                //add a listener to start the slider autoplay again
                playerVimeo.on('ended', function () {
                    setTimeout(function() {
                        coverSwiper.autoplay.start();
                    },250);
                });

                playerVimeo.pause();
                vimeoPlayers[videoId] = playerVimeo;
            });
        }

        if ($('.cover-module .slide_video').length) {
            coverSwiper.on('slideChangeTransitionEnd', function () {
                //when a video is displayed
                //stop the old one
                //plays the new one
                //stop the slider until the end
                //store the video as last played
                if ($(coverSwiperClass + ' .swiper-slide-active').find('iframe.vimeo_video').length) {

                    coverSwiper.autoplay.stop();

                    $(coverSwiperClass + ' .swiper-slide-active').find('iframe.vimeo_video').each(function () {
                        var videoId = $(this).attr('id');
                        setLastVideoPause(videoId);
                        vimeoPlayers[videoId].play();
                        lastPlayedVideo = videoId;

                    });
                } else if ($(coverSwiperClass + ' .swiper-slide-active').find('iframe.youtube_video').length) {
                    coverSwiper.autoplay.stop();
                    $(coverSwiperClass + ' .swiper-slide-active').find('iframe.youtube_video').each(function () {
                        var videoId = $(this).attr('id');
                        setLastVideoPause(videoId);
                        youtubePlayers[videoId].playVideo();
                        lastPlayedVideo = videoId;
                    });
                }
            });
        }
    }

    // with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if (lastPlayedVideo != '') {
            if ($('#' + lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            } else if ($('#' + lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }

    /* START VIDEO ON SLIDECHANGE */
    if (coverSwiper && $('.cover-module slide_video').length) {
        coverSwiper.on('slideChangeTransitionStart', function () {
            if($('.swiper-slide-active').find('.slide_video').length){
                var video = $('.cover-module .swiper-slide-active iframe');
                var source = video.attr('src');
                video.attr('src', '');

                setTimeout(function(){
                    video.attr('src', source);
                }, 5);
            }
        });
    }

    $("#coverSwiper").each(function(elem, target){
        var swp = target.swiper;
        $(this).hover(function() {
            swp.autoplay.stop();
        }, function() {
            swp.autoplay.start();
        });
    });

    if ($('.homepage .swiperTg .swiper-slide').length > 3) {
        var swiperTg = new Swiper('.swiperTg', {
            slidesPerView: 2,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                1281: {
                    slidesPerView: 3,
                },
            },
        });
    }

    if ($(".social-1 .swiper_insta").length) {
        instaSwiper = new Swiper(".social-1 .swiper_insta", {
            slidesPerView: 3,
            spaceBetween: 80,
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            speed: 2500
        });
    }

    //CATEGORY SLIDER
    if ($(".category .bloc_sub_categ .swiper-slide").length > 4) {
        categoryPushSwiper = new Swiper(".category .bloc_sub_categ.swiper-container", {
            slidesPerView: 4,
            spaceBetween: 50,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    } else {
        if ($(".category .bloc_sub_categ .swiper-slide").length) {
            $('.category .wrapper_bandeau .swiper-button-next, .category .wrapper_bandeau .swiper-button-prev').hide();
        }
    }


    //ASSOCIATION SLIDER
    if ($(".product_page #associations_slider .swiper-slide").length > 4) {
        categoryPushSwiper = new Swiper(".product_page #associations_slider", {
            slidesPerView: 4,
            spaceBetween: 8,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    } else {
        if ($(".product_page #associations_slider .swiper-slide").length) {
            $('.category .wrapper_bandeau .swiper-button-next, .category .wrapper_bandeau .swiper-button-prev').hide();
        }
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

/*********************************
 Custom Select Menu
 *********************************/

 function initCustomSelect(select) {

    var x, i, j, selElmnt, a, b, c;
    if (select === undefined) {
        select = 'custom-select';
    }
    x = document.getElementsByClassName(select);
    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }

    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;
                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // Raz de tous les autres select
            closeAllSelect(this);
            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");
            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {

    if (select_str === undefined) {
        select_str = '.custom-select';
    }
    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();
        // Raz de tous les autres select
        closeAllSelect(this);
        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");
        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });
    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;
        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }
                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);
$(document).ready(function () {
    initCustomSelect();
    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;
        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });
});

function closeRecherche(){
    if($('.wrap_search_field').is(":visible")) {
        $('.wrap_search_field').fadeOut();
        $('#site_head_wrap').removeClass('highlighted');
        $('#shad_menu').removeClass('actif');
        $("html,body").animate({scrollTop: 0}, 400);
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: "post",
        data: {lang: locale, url: uri},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}


/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
 function achatExpressV2(product_id, regroup_ref_id) {
    $('#ligne_pointure_' + product_id + regroup_ref_id).show();
    $('#ligne_couleur_' + product_id + regroup_ref_id).hide();
    $("#prod_info_" + product_id + regroup_ref_id + " .bloc_add_color").hide();
}



// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        // Depending on the color we see if the product is available, do this only in product page
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);

        // We check if the product is already in the wishlist
        if (!isAchatExpressV2) {
            checkProductInWishlist(productId, productRef, true);
        }
    }

}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hided');

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = form.getValue('produit_id');
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }
    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            if (res.stock_tampon == 0) {
                $(".eclat_product_nouveaute_fiche_produit").show();

                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express && is_wishlist == false) {
                    $("#bloc_add_basket").hide();
                    if (!$(".w-alertestock-form").length) {
                        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                    }
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 5) {
                    if (res.stock_tampon == 1) {
                        $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                    } else {
                        $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                    }
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {
                    waitingAddToBasket(event, form, '', itm_rr_id);

                    if (from == 'product') {
                        // Show bloc colors when we add product to basket
                        $('#ligne_pointure' + elem).hide();
                        $('#ligne_couleur' + elem).show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    } else if (from == 'lookbook') {
                        closeMultiShad('lightbox_achat_express_look');
                    }

                } else {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                }
            };
        }
    });
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        // DataLayer addToCart
        if (array_panier[5] != undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 2) {
            $("#cart_top .cart_product_listing").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}


// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                //update top cart
                var response = ajax_file(create_link('ajax_show_basket'));
                document.getElementById('cart_top').innerHTML = response;
            }
        });
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function createDatalayer(prod_id, url) {
    if (productlist_gondole){
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id){
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
                create_link('ajax_update_basket') +
                "?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function() {
        topCartDesappear();
    }, 5000);
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
 function topCartAppear() {
    var show_top_cart_parent = $('.block_top_right');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    if(count_bask >= 2){
        $("#cart_top .cart_product_listing").overlayScrollbars({});
    }

    show_top_cart_parent.addClass("hoverbasket");
    $('#shad_menu').addClass("actif");

    $('#site_head_wrap').addClass('addindbasket');

}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart_parent = $('.block_top_right');
    show_top_cart_parent.removeClass("hoverbasket");
    $('#shad_menu').removeClass("actif");
    $('#site_head_wrap').removeClass('addindbasket');
}

function paginate(page) {

    var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat), // 242
        nb_prod = form.getValue('nb_prod', parseFloat), // 30
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

    page = page || 1;

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_pagination'),
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            var productLoaded = $('#list_item .item').length;

            pagination.each(function () {
                if (see_all === 0 && nb_prod < total_element) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + productLoaded + ' / ' + total_element + ' ' +translate('2_product') +'</div><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 1, true)">' + translate('see_all') + '</div>';
                }  else if (total_element > nb_prod_default && (productLoaded != total_element)) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + productLoaded + ' / ' + total_element + ' ' + translate('2_product') + '</div>';
                } else if (productLoaded == total_element) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + productLoaded + ' / ' + total_element + ' ' + translate('2_product') + '</div><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0, false)">' + translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}


var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + create_link('ajax_deconnect'));

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

function process_type_preco() {

    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + create_link('ajax_choose_preco'),
        success: function(response) {

            if (response.status == "success") {

                if ($("input[name=preco_choice]").length == 0) {

                    closeMultiShad("lightbox_preco");
                }

                var index_conveyor = typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {

                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {

                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr( "checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {

                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked","checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    closeMultiShad(elem);
}

function openMultiShad(id, slide) {
    $("#" + id).addClass('actif').show();
    $("#shad").addClass('actif').show();

    $("#shad").on("click", function () {
        closeMultiShad(id);
    });

    if (id === "sizeguideBox") {

        $('#shad_sizeguide').addClass('actif').show();
        $("#shad_sizeguide").on("click", function () {
            closeMultiShad("sizeguideBox");
        });
    }

    if(id === "zoomBox") {

        $('body').css('overflow', 'hidden');

        $('.productLightboxVisu .img_large').on('click', function () {
            $('body').css('overflow', 'initial');
            closeMultiShad('zoomBox');
        });
        if($("#thumbs_scroller").length > 0) {
            var zoomThumbs = new Swiper('#thumbs_scroller', {
                slidesPerView: 'auto',
                spaceBetween: 7,
                direction: 'vertical',
            });
        }
        if($("#zoom_box_wrapper").length) {
            var main_productVisu = new Swiper('#zoom_box_wrapper', {
                direction: 'vertical',
                slidesPerView: 1,
                navigation: {
                    nextEl: '.thumbsNext',
                    prevEl: '.thumbsPrev',
                },
                thumbs: {
                    swiper: zoomThumbs
                },
                preloadImages: false,
                lazy: true,
                loadPrevNext: true,
                loadOnTransitionStart: true
            });
        }
    }

    if (id == 'selection-offer') {

        /* SWIPER SELECTION-OFFER-CONTENT */
        /* CHOIX COULEUR */
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptate des swiper slide
        $.each($('#selection-offer-content .color_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 4) {
                new Swiper($(this).get(), {
                    slidesPerView: 5,
                    navigation: {
                        nextEl: '.nextColour',
                    }
                });
            }
        });

        /* CHOIX SIZE */
        //On check individuellement chacun des sliders avec each() afin de ne pas fausser le comptate des swiper slide
        $.each($('#selection-offer-content .size_list.swiper-container'), function() {
            if ($(this).find('.swiper-slide').length > 3) {
                new Swiper($(this).get(), {
                    slidesPerView: 3.5,
                    navigation: {
                        nextEl: '.nextSize',
                    }
                });
            }
        });
    }
}

function closeMultiShad(id) {

    if (id === 'sizeguideBox') {
        if ($('#lightbox_achat_express').hasClass('actif')) {
            $('#shad_sizeguide').removeClass('actif').hide();
            $("#" + id).removeClass('actif');
        } else {
            $("#shad").off("click");
            $("#" + id).removeClass('actif');
            $("#shad").removeClass('actif');
        }
    }
    else {
        $("#shad").off("click");
        $("#" + id).removeClass('actif');
        $("#shad").removeClass('actif');
    }

}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
        },
        url: path_relative_root + create_link('ajax_removeBasketProd'),
        success: function(res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top").html(res.datas.html);
            $('#show_top_cart').addClass('appear');

            var count_bask = $('#cart_top .cart').attr('data-cart')

            if(count_bask > 2){
                $("#cart_top .cart_product_listing").overlayScrollbars({});
            }
        },
    });
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).hide();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.form_submit.bloc_add_color').show();

    //Reset input selection
    $('#size_list_' + elem + ' input').prop('checked', false);
}

/**
 * Update colors / size picto depends on stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
 function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var currentCorlor = $(this).val();
        var size_box, is_disabled;

        // Disable all colors without stock
        // For each colisage
        window.json_colisages.forEach(function(colors) {
            // For each colors
            Object.keys(colors).forEach(function(color_id) {
                var no_sizes = 0;
                // For each size
                Object.keys(colors[color_id]).forEach(function(size_id) {
                    if (color_id == currentCorlor) {
                        if (colors[color_id].hasOwnProperty(size_id)) {
                            size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                                '[value="' + size_id + '"]'
                            );
                            is_disabled = !colors[color_id][size_id];
                            if( is_disabled )  {
                                size_box.addClass('disabled');
                            }
                            else {
                                size_box.removeClass('disabled');
                            }
                        }
                    }

                    // If stock for this size / color doesn't exist
                    if (colors[color_id][size_id] == false) {
                        no_sizes++;
                    }
                });
                // If product don't have any size / colors stock
                if (Object.keys(colors[color_id]).length == no_sizes) {
                    $('#color_'+color_id+'+label').addClass('no_sizes');
                } else {
                    $('#color_'+color_id+'+label').removeClass('no_sizes');
                }
            });
        });
    }
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
                create_link('ajax_liste_quantity') +
                "?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function filterTrigger(){
    if($('#rayon_sticky_block').length) {
        var trigger = $('#trigger_filtre');
        var filter = $('#rayon_sticky_block');
        var shad = $('#shad_link');
        var header = $('#site_head_wrap');

        if(!trigger.hasClass('triggered')) {
            trigger.addClass('triggered');
            filter.addClass('show');
            header.addClass('filters_in');
            shad.addClass('actif filterOn');
            $("#filter_sticky").lionbars();

        } else if(trigger.hasClass('triggered')) {
            closeFiltre();
        }

        if(trigger.hasClass('triggered')) {
            var shad_filtre = $('#shad_link.filterOn');
            shad_filtre.on("click touch", function(){
                closeFiltre();
            });
        }

        function closeFiltre(){
            if(trigger.hasClass('triggered')) {
                trigger.removeClass('triggered');
                filter.removeClass('show');
                header.removeClass('filters_in');
                shad.removeClass('actif filterOn');
            }
        }
    }
}

function viewByTwo(){
    if($('#list_item').length && $('#list_item').hasClass('viewbyFour')){
        $('#list_item').removeClass('viewbyFour');
        $('#list_item').addClass('viewbyTwo');
        $('#viewChanger .byTwo').addClass('actif');
        $('#viewChanger .byFour').removeClass('actif');
        createCookie("nb_produits", 2, 1);
    }
}

function viewDefault(){
    if($('#list_item').length && $('#list_item').hasClass('viewbyTwo')){
        $('#list_item').removeClass('viewbyTwo');
        $('#list_item').addClass('viewbyFour');
        $('#viewChanger .byTwo').removeClass('actif');
        $('#viewChanger .byFour').addClass('actif');
        createCookie("nb_produits", 4, 1);
    }
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

$(document).ready(function () {

    if(readCookie("nb_produits") !== null){
        // Default view
        if (readCookie("nb_produits") == 4) {
            if ($('#list_item').hasClass('viewbyTwo')) {
                $('#list_item').removeClass('viewbyTwo');
                $('#list_item').addClass('viewbyFour');
                $('#viewChanger .viewone').addClass('actif');
                $('#viewChanger .viewtwo').removeClass('actif');
            }
        //view 2
        } else if (readCookie("nb_produits") == 2) {
            if (!$('#list_item').hasClass('viewbyTwo')) {
                $('#list_item').removeClass('viewbyFour');
                $('#list_item').addClass('viewbyTwo');
                $('#viewChanger .viewtwo').removeClass('actif');
                $('#viewChanger .viewone').addClass('actif');
            }
        }
    }
});

var tempo_reset = false;

function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

}

var bloc_prod_xhr;
//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        total_element = form.getValue('totalElems', parseFloat),
        output = {},
        is_from_page = false,
        key;

    //Ajout pour le scroll infini vers le haut
    if(fromScrollTop || fromScrollBottom){
        $('#is_loading').val('1');
    }

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');

    if (nb_product !== undefined) {

        form.nb_prod.value = nb_product;
    } else {

        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;
    }
    // Add misc values to output object
    output.page = form.page.value;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = form.totalElems.value;
    output.current_page = page + 1;

    if (idObj.split('~').length > 1) {

        output.multi_object = true;
    }

    if (document.getElementById('select_date')) {

        output.date = 4;
    }
    if (document.getElementById('choice_tri') && document.getElementById('choice_tri').value != '0') {

        output.tri = document.getElementById('choice_tri').value;
    }

    /** LEGACY **/

    //Recuperation du tri par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        output.tri = res[1][0];
    }

    /** END LEGACY **/
    // Si le prix existe
    if ($('input[name="price_min"]').length > 0) {
        // Récupération du prix
        output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);
    }

    if (page === 'all') {

        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0]) || page_value > 0) {

            var marginTop;
            if($('.wrapper_bandeau')){
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {
                var //new_elm = document.createElement('div'),
                    old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {}
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {}
                    }

                    /** END LEGACY **/
                }
                // generateFormatVisu();
            },
            complete: function () {
                //force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200);
                    }, 250);
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                if(fromScrollTop || fromScrollBottom){
                    $('#is_loading').val('0');
                }

                if(fromScrollBottom){
                    $("#initial_page").val(page +1);
                }

                /**
                * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                */
                var productLoaded = $('.wrapperProdVertical').length;
                var percentLoaded = (productLoaded*100/total_element);
                var total_element = document.getElementById('totalElems').value;

                $('.nbProdDisplayed').html(productLoaded + ' / ' + total_element);

                $('.txt_bandeau span').html(total_element + ' ' + Translator.translate('2_product'));

                $('.loader_scroll').removeClass('loading');

                if(productLoaded >= total_element) {

                    $('.loader_scroll').hide();
                }
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    }
}


// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur' , '', itm_rr_id);
        } 
    }
}

function changeVisu(field, path, evt) {

    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {

        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {

            if (elm !== target) {

                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {

            this.alt = field.alt;

            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var productRef = this.getValue('produit_ref');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    var search = form.getValue('isSearch');

    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_ref : productRef,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        search : (search ? true : false)
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link("ajax_get_product_regroup_ref"),
        data : data,
        success : function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;
                if (data.type_page == 'product') {

                    // Main HTML
                    var $html_main = $(stripCombo(datas.html_main));
                    //Save ariane to prevent delete
                    var saveAriane = $('.product_main_wrapper #breadcrumbs');
                    // HTML Photo et informations
                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    // HTML Associated products
                    var product_associations = $('#product_associations', $html_main);

                    // HTML CMS module
                    var product_cms_bloc = $('#product_cms_bloc', $html_main);

                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }
                    } else {
                        // Update HTML Photo et informations
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        //Replace ariane by the previous one
                        $('#breadcrumbs').replaceWith(saveAriane);
                        // Update HTML Associated products
                        $('#product_associations', '#site_global_wrap').html(product_associations.html());

                        // Update HTML CMS module
                        $('#product_cms_bloc', '#site_global_wrap').html(product_cms_bloc.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        /*FP sliders*/
                        if ($("#visual_scroller_wrapper").length) {
                            var productThumbs = new Swiper('#visual_scroller', {
                                slidesPerView: 4,
                                spaceBetween: 8,
                                direction: 'vertical',
                                navigation:
                                {
                                    nextEl: '.thumbNext',
                                    prevEl: '.thumbPrev',
                                },
                            });
                        }

                        // PRODUCT SWIPER
                        if ($("#jqzoom").length) {
                            var main_productVisu = new Swiper('#jqzoom', {
                                direction: 'vertical',
                                loadOnTransitionStart: true,
                                thumbs: {
                                    swiper: productThumbs
                                },
                                slidesPerView: 1,
                                lazy: true,
                            });
                        }

                        if ($(".edito_swiper .swiper-slide").length > 2) {
                            edito_swiper = new Swiper(".edito_swiper", {
                                 slidesPerView: 2,
                                 spaceBetween: 7,
                                 navigation: {
                                     nextEl: '.edito_swiper_next',
                                     prevEl: '.edito_swiper_prev',
                                 },
                                 breakpoints: {
                                    1190: {
                                        slidesPerView: 3,
                                        spaceBetween: 7,
                                    },
                                    1160: {
                                        slidesPerView: 2,
                                        spaceBetween: 5,
                                    },
                                },
                             });
                        }
                    }
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {
            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                checkSubmitState();
                eventButton();
            }, 500);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');


            if (!data.is_achat_express_v2) {
                getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist, data.produit_ref);
                checkProductInWishlist(data.produit_id_new, data.produit_ref);
            }

        }
    });
}

/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
 function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}

$(document).on("click", ".thumblnk", function(evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.next(".thumbcnt");

    evt.preventDefault();

    thumbnav
        .find(".thumblnk")
        .not(".thumblnk[data-pannel=" + id_pannel + "]")
        .removeClass("current");
    thumbcnt
        .find(".thumbslide")
        .not(".thumbslide[data-pannel=" + id_pannel + "]")
        .removeClass("current");

    thumbnav
        .find(".thumblnk[data-pannel=" + id_pannel + "]")
        .addClass("current");
    thumbcnt
        .find(".thumbslide[data-pannel=" + id_pannel + "]")
        .addClass("current");
});

function zoomImg(slide) {
    $('#zoom_box_wrapper').css('opacity', 0);
    window.setTimeout(function () {
        //Reset thumbs class

        if (slide){
            goto = slide;
        }

        goto = goto+1;
    }, 50);

    window.setTimeout(function () {
        $('#zoom_box_wrapper').animate({opacity: 1}, 650);
    }, 350);

    openMultiShad('zoomBox');
}


function updateSrc(path_web, dest) {
    var tmp_string_large = document.getElementById("img_large").src;
    tmp_string = tmp_string_large.replace(/medium/, "large");

    document
        .getElementById("img_large")
        .setAttribute(
            "onclick",
            "zoomImg('" + tmp_string + "','" + path_web + "')"
        );
    document.getElementById("loupe").href =
        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

    if (dest == "vue") {
        var i = 0;
        var trouve = false;
        while (i < tab_js.length && !trouve) {
            trouve =
                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
            i++;
        }

        cpt_img = i - 1;
    }
}

function ajax_achat_express(reference, wishlist = false, idprod, code_couleur){
    var arg = '';

    if (wishlist == true) {
        arg += '&wishlist';
    }

    $.ajax({
        type: 'get',
        url: path_relative_root + create_link('ajax_achat_express') + "?ref="+reference+'&couleur='+code_couleur+arg,
        success: function( response ){
            if(!$('#lightbox_achat_express').length){
                $('body').append('<div id="lightbox_achat_express" class="lightbox"></div>');
            }
            $('#lightbox_achat_express').html( '<div id="shad_sizeguide" class="shad"></div><div class="express_response"><button class="bgSprite bfr wool close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')"></button>'+ response +'</div>' ).appendTo( 'body' );
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);
        }
    });
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('.otherProduct')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

function seeXProduct(nb_product, page, infinite) {
    // Enable scroll infinis
    if (nb_product === 30 && infinite == true) {
        $("#see_all").val("1");
        generateNewBlocProd((page -1));
        $("#initial_page").val(page);
    } else { // disable scroll infinis
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);
        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

/* Changer le positionnement du bloc product_attributs */
$(function() {
    if ($('body.step_1 .pushBasketWrapper').length) {
        $('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .bloc_question');

        window.setTimeout(function() {
        $('body.step_1 .pushBasketWrapper').addClass('active');
        }, 200);
    }
    if ($('body.step_1 .pushBasketWrapperText').length) {
        $('body.step_1 .pushBasketWrapperText').insertBefore('body.step_1 .cart_main_title.basket');

        window.setTimeout(function() {
            $('body.step_1 .pushBasketWrapperText').addClass('active');
            }, 200);
    }
});

/* Changer le libellé couleur fiche produit */
function changeLibelleCouleur(color_name = null) {
    if (color_name) {
        $(".libelle_couleur").text(color_name);
    } else {
        var selected_color = $('input[name=itm_color]:checked').data('nom');
        $(".libelle_couleur").text(selected_color);
    }
}